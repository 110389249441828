export default {
  "wrapper": "wJ",
  "header": "ws",
  "no-separator": "wF",
  "tabs": "wg",
  "tab": "wD body-1 overlay",
  "selected": "wV",
  "organization-list": "wL",
  "content": "wT",
  "empty-state": "wz",
  "illustration": "wa",
  "body": "wH body-1",
  "cta": "wO btn btn--tertiary"
};
