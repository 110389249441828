export default {
  "tooltip": "wK",
  "wrapper": "wG",
  "collapsed": "wr",
  "label": "wb body-1",
  "selected": "wy",
  "icon-wrapper": "wU",
  "icon": "wj",
  "locked-icon": "wW",
  "notification-count": "wu",
  "fade-in": "NS",
  "responsive": "Nc"
};
