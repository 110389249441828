export default {
  "item": "Nd",
  "qonto-pilot-nav-item": "NI",
  "wrapper": "Nt",
  "collapsed": "NM",
  "label": "NP body-1",
  "icon-wrapper": "Nl",
  "icon": "NX",
  "key": "NC",
  "keys": "Nk"
};
