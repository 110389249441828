export default {
  "sub-menu": "NY",
  "collapsed": "Nh",
  "collapse-left": "Nf",
  "responsive": "NK",
  "expanded": "NG",
  "no-slide": "Nr",
  "visible": "Nb",
  "hidden": "Ny",
  "group": "NU",
  "promo-box": "Nj"
};
