/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ORGA_STATUS } from 'qonto/constants/organization';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class DiscoverTrialConfirmComponent extends Component {
  @service segment;
  @service router;
  @service modals;
  @service store;
  @service intl;
  @service toastFlashMessages;
  @service subscriptionManager;
  @service organizationManager;

  @tracked trialPricePlan;
  @tracked subscription;
  @tracked nextBillingDate;
  @tracked hasInsufficientFunds = false;

  trialPricePlanId = this.hasModularPricing
    ? this.args.data.trialInfo.productId
    : this.args.data.trialInfo.pricePlanId;
  recurrence = this.args.data.trialInfo.recurrence;

  get hasModularPricing() {
    return this.organizationManager.organization.hasModularPricing;
  }

  constructor() {
    super(...arguments);
    if (!this.isAccountClosed) {
      this.fetchDataTask.perform().catch(ignoreCancelation);
    }
  }

  get context() {
    return {
      subscription: this.subscription,
      recurrence: this.recurrence,
      estimatedPrice: { next_billing_date: this.nextBillingDate },
      hasInsufficientFunds: this.hasInsufficientFunds,
    };
  }

  @action
  transitionToNext(hasProductChangeNotAvailableError) {
    this.router.refresh('protected');

    if (hasProductChangeNotAvailableError) {
      this.modals.open('discover/trial/success-italy');
    } else {
      this.modals.open('discover/trial/success', {
        recurrence: this.recurrence,
        currentPlan: this.subscriptionManager.currentPricePlan,
        targetPlan: this.trialPricePlan,
      });
    }

    this.args.close();
  }

  get isAccountClosed() {
    return this.organizationManager.organization.status === ORGA_STATUS.DEACTIVATED;
  }

  fetchDataTask = dropTask(async () => {
    if (this.hasModularPricing) {
      this.trialPricePlan = await this.store.findRecord(
        'subscription-product',
        this.trialPricePlanId
      );
      this.subscription = this.store.createRecord('organization-subscription-new', {
        organization: this.organizationManager.organization,
        product: this.trialPricePlan,
        recurrence: this.recurrence,
      });
    } else {
      this.trialPricePlan = await this.store.findRecord('price-plan', this.trialPricePlanId);
      this.subscription = this.store.createRecord('organization-subscription', {
        organization: this.organizationManager.organization,
        pricePlan: this.trialPricePlan,
        recurrence: this.recurrence,
      });
    }

    let estimatedPrice;
    try {
      if (this.hasModularPricing) {
        let { total_estimate } = await this.subscription.estimate();
        estimatedPrice = total_estimate;
      } else {
        let { estimated_price } = await this.subscription.confirmFlight();
        estimatedPrice = estimated_price;
      }
    } catch ({ payload, status }) {
      if (status === 422) {
        let errors;
        if (this.hasModularPricing) {
          errors = payload.errors;
          estimatedPrice = payload.total_estimate;
        } else {
          errors = payload.errors;
          estimatedPrice = payload.estimated_price;
        }
        this.hasInsufficientFunds = errors.some(
          ({ code }) => code === 'balance_insufficient_funds'
        );
      } else {
        let errorMessage = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(errorMessage);
      }
    }

    this.nextBillingDate = estimatedPrice?.next_billing_date;
  });
}
