/* import __COLOCATED_TEMPLATE__ from './attachments-suggested.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class AttachmentsSuggestedComponent extends Component {
  @service intl;
  @service store;
  @service segment;
  @service toastFlashMessages;
  @service organizationManager;
  @service sentry;

  @tracked suggestedAttachments = [];
  @tracked selectedSuggestedAttachmentIndex = 0;
  @tracked searchQuery = '';

  constructor() {
    super(...arguments);
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.fetchSuggestedAttachmentsTask.perform();
  }

  get selectedSuggestedAttachment() {
    return this.suggestedAttachments[this.selectedSuggestedAttachmentIndex];
  }

  @action handleInvoicesSearch(event) {
    this.searchQuery = event;
  }

  fetchSuggestedAttachmentsTask = dropTask(
    waitFor(async () => {
      try {
        let attachments = await this.store.query('attachment', {
          organization_id: this.organizationManager.organization.id,
          filters: { ids: this.args.transaction.attachmentSuggestionIds },
          per_page: 500,
        });
        // attachmentSuggestionIds is sorted by matching score, and we should keep this order
        // for displaying suggestedAttachments (note: matching score is not part of attachment object)
        this.suggestedAttachments = this.args.transaction.attachmentSuggestionIds.map(id =>
          attachments.find(att => att.id === id)
        );
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);

        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    })
  );

  linkSuggestedAttachmentTask = dropTask(
    waitFor(async () => {
      try {
        if (!this.args.transaction.attachments.includes(this.selectedSuggestedAttachment)) {
          this.args.transaction.attachments.push(this.selectedSuggestedAttachment);
        }

        await this.args.transaction.linkAttachment([this.selectedSuggestedAttachment]);
        this.segment.track('attachments_suggestion_modal_validation_button_clicked', {
          membership_role: this.organizationManager.membership.role,
          suggestion_order: this.selectedSuggestedAttachmentIndex,
        });
        this.args.onClose();
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    })
  );

  @action
  selectSuggestedAttachment(attachmentIndex) {
    this.selectedSuggestedAttachmentIndex = attachmentIndex;
  }
}
