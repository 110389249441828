export default {
  "row": "Dg",
  "active": "DD",
  "cell": "DV",
  "name-cell": "DL",
  "email-cell": "DT",
  "actions-cell": "Dz",
  "name-container": "Da",
  "quick-actions-wrapper": "DH",
  "empty": "DO",
  "name-icon-container": "Dm",
  "icon": "Dp",
  "tooltip-container": "Dx"
};
