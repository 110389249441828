/* import __COLOCATED_TEMPLATE__ from './dual-nav.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { CATEGORIES as CONNECT_CATEGORIES } from 'qonto/constants/connect';
import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';
import { switchingBankJsURL } from 'qonto/constants/hosts';
import { CATEGORIES as INSURANCE_CATEGORIES } from 'qonto/constants/insurance-hub';
import { INTERNATIONAL_OUT_PROMO_BOX_LOCAL_STORAGE_KEY } from 'qonto/constants/international-out/storage';
import { ROLES } from 'qonto/constants/membership';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import filterMenuItems from 'qonto/utils/dual-nav/filter-menu-items';
import findActiveMainMenuItem from 'qonto/utils/dual-nav/find-active-main-menu-item';
import findActiveSubMenuItem from 'qonto/utils/dual-nav/find-active-sub-menu-item';
import {
  getBadgeVisitedRoutes,
  processNewBadge,
  setStorageBadge,
} from 'qonto/utils/dual-nav/new-badge';
import processMenuItems from 'qonto/utils/dual-nav/process-menu-items';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

function pipe(...fns) {
  return value => fns.reduce((value, fn) => fn(value), value);
}

const DEBOUNCE = Object.freeze({
  ON_COLLAPSE: 50,
  AFTER_COLLAPSE: 200,
});

export default class DualNavComponent extends Component {
  @service intl;
  @service abilities;
  @service internationalOutManager;
  @service linkManager;
  @service localeManager;
  @service organizationManager;
  @service subscriptionManager;
  @service notifierCounterManager;
  @service media;
  @service router;
  @service menu;
  @service segment;
  @service userManager;
  @service featuresManager;

  @tracked activeMenuItem = findActiveMainMenuItem([...this.items, ...this.settingsItems]);
  @tracked activeSubMenuItem = findActiveSubMenuItem(this.activeMenuItem);
  @tracked state = this.getStateForActiveMenuItem();
  @tracked hoveredMenuItemId = null;

  @tracked visitedRoutes = getBadgeVisitedRoutes(this.userManager.currentUser.id);

  @tracked hasNewInternationalOutFeature = false;

  @reads('organizationManager.organization') currentOrganization;

  constructor() {
    super(...arguments);

    this.onRouteDidChange = this.onRouteDidChange.bind(this);
    this.media.on('mediaChanged', this, this.onMediaChanged);
    this.router.on('routeDidChange', this.onRouteDidChange);
    this.menu.updateBreadcrumbs(this.breadcrumbsTrail);

    if (
      this.abilities.can('use international euro target currency') &&
      !safeLocalStorage.getItem(INTERNATIONAL_OUT_PROMO_BOX_LOCAL_STORAGE_KEY)
    ) {
      this.fetchInternationalOutEligibilityTask.perform().catch(ignoreCancelation);
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.media.off('mediaChanged', this, this.onMediaChanged);
    this.router.off('routeDidChange', this.onRouteDidChange);
  }

  onMediaChanged() {
    this.state = this.getStateForActiveMenuItem();
    this.menu.updateBreadcrumbs(this.breadcrumbsTrail);
  }

  onRouteDidChange() {
    let activeMenuItem = findActiveMainMenuItem([...this.items, ...this.settingsItems]);

    let activeMenuSubItem = findActiveSubMenuItem(activeMenuItem);
    this.activeSubMenuItem = activeMenuSubItem;
    // Check the active route has new badge before setting the route as visited in the localstorage
    if (activeMenuSubItem?.isNew) {
      // Store the active route with the new badge
      setStorageBadge(activeMenuSubItem, this.userManager.currentUser.id);
    }

    this.activeMenuItem = activeMenuItem;
    this.state = this.getStateForActiveMenuItem();
    this.menu.updateBreadcrumbs(this.breadcrumbsTrail);
    this.visitedRoutes = getBadgeVisitedRoutes(this.userManager.currentUser.id);
  }

  getStateForActiveMenuItem() {
    if (this.media.isSm) {
      return DUAL_NAV_STATES.RESPONSIVE;
    }
    return this.activeMenuItem?.groups ? DUAL_NAV_STATES.COLLAPSED : DUAL_NAV_STATES.EXPANDED;
  }

  @action
  onMainMenuButtonPointerUp(item) {
    if (this.state === DUAL_NAV_STATES.RESPONSIVE) {
      this.hoveredMenuItemId = item.id;
      return;
    }

    this.segment.track(item.tracking);
    this.state = DUAL_NAV_STATES.COLLAPSED;
    this.activeMenuItem = item;
  }

  @action
  onMainMenuButtonKeyboardEvent(item) {
    this.hoveredMenuItemId = item.id;
    this.segment.track(item.tracking);

    if (this.state !== DUAL_NAV_STATES.RESPONSIVE) {
      this.state = DUAL_NAV_STATES.COLLAPSED;
    }
  }

  @action
  onMainMenuLinkPointerUp(item) {
    this.segment.track(item.tracking);
    this.hoveredMenuItemId = null;

    if (this.state === DUAL_NAV_STATES.RESPONSIVE) return;

    this.state = DUAL_NAV_STATES.EXPANDED;
    this.activeMenuItem = item;
  }

  @action
  onMainMenuLeave() {
    if (this.onPointerEnterTask.isRunning) {
      this.onPointerEnterTask.cancelAll();
    }
  }

  @action
  cancelContentHover() {
    if (this.onContentHoverTask.isRunning) {
      this.onContentHoverTask.cancelAll();
    }
  }

  @action
  onSubItemClick(mainMenuItem, subMenuItem) {
    this.activeMenuItem = mainMenuItem;
    this.hoveredMenuItemId = null;
    this.segment.track(subMenuItem.tracking);

    if (this.state !== DUAL_NAV_STATES.RESPONSIVE) {
      this.state = DUAL_NAV_STATES.COLLAPSED;
    } else {
      this.hoveredMenuItemId = null;
    }
  }

  fetchInternationalOutEligibilityTask = dropTask(async () => {
    this.hasNewInternationalOutFeature = await this.internationalOutManager.isEligible();
  });

  onContentHoverTask = restartableTask(async event => {
    if (event.pointerType !== 'touch') {
      this.onPointerEnterTask.cancelAll();
      await timeout(this.debounce);
    }

    this.hoveredMenuItemId = null;
  });

  onPointerEnterTask = restartableTask(async item => {
    await timeout(this.debounce);
    this.hoveredMenuItemId =
      this.state !== DUAL_NAV_STATES.COLLAPSED && item.groups ? item.id : null;
  });

  get items() {
    return pipe(
      filterMenuItems,
      processNewBadge(this.visitedRoutes),
      processMenuItems
    )(this.#rawMainItems);
  }

  get settingsItems() {
    return pipe(
      filterMenuItems,
      processNewBadge(this.visitedRoutes),
      processMenuItems
    )(this.#rawSettingsItems);
  }

  get debounce() {
    if (macroCondition(isTesting())) return 0;
    let time = Number(safeLocalStorage.getItem('DUAL_NAV_DEBOUNCE')) || DEBOUNCE.AFTER_COLLAPSE;
    return this.hoveredMenuItemId ? time : DEBOUNCE.ON_COLLAPSE;
  }

  get activeMenuItemId() {
    return this.activeMenuItem?.id;
  }

  get isSubMenuVisible() {
    let { state, hoveredMenuItemId, items, settingsItems } = this;

    if (!hoveredMenuItemId) return false;

    let isHoveredItemButton = Boolean(
      [...items, ...settingsItems].find(({ id }) => id === hoveredMenuItemId)?.groups
    );

    return (
      [DUAL_NAV_STATES.EXPANDED, DUAL_NAV_STATES.RESPONSIVE].includes(state) && isHoveredItemButton
    );
  }

  get breadcrumbsTrail() {
    if (!this.#shouldShowBreadcrumbs) return [];

    let isTransactionsRoute = this.router.currentRouteName?.includes('transactions');
    return isTransactionsRoute
      ? [this.activeMenuItem.label, this.intl.t('dual-nav.sub.business-account.transactions')]
      : [this.activeMenuItem.label];
  }

  get #shouldShowBreadcrumbs() {
    return this.activeMenuItem?.groups && this.state === DUAL_NAV_STATES.RESPONSIVE;
  }

  get #employeeMainMenuItems() {
    let isEmployeeWithTransferRequestAbility =
      this.abilities.can('create transfer request') &&
      this.abilities.cannot('review transfer request');

    if (isEmployeeWithTransferRequestAbility) {
      return [
        this.#transactionsItem,
        this.#requestsItem,
        this.#cardsItem,
        this.#reimbursementsEmployeeViewItem,
        this.#transfersEmployeeViewItem,
      ];
    }

    return [
      this.#transactionsItem,
      this.#cardsItem,
      this.#reimbursementsEmployeeViewItem,
      this.abilities.can('navigate request') && this.#requestsItem,
    ];
  }

  get #rawMainItems() {
    let {
      membership: { role },
      organization: { isDeactivated },
    } = this.organizationManager;

    let isEmployee = role === ROLES.EMPLOYEE;

    if (isEmployee) {
      return this.#employeeMainMenuItems;
    }

    if (this.#shouldShowKDeposit) {
      return [this.#capitalDepositMainMenuItem];
    }

    return [
      this.abilities.can('navigate overview') && this.#overviewMainMenuItem,
      this.abilities.can('navigate task') && this.#tasksMainMenuItem,
      this.abilities.can('navigate request') && !isEmployee && this.#requestsItem,
      this.#businessAccountMainMenuItem,
      !isDeactivated && this.#invoicesMainMenuItem,
      this.#teamExpensesMainMenuItem,
      this.featuresManager.isEnabled('displayCashflowV1') &&
        this.abilities.can('navigate overview') &&
        this.#cashFlowMainMenuItem,
      this.#isSavingsSectionVisible && this.#savingsMainMenuItem,
      this.#financingMainMenuItem,
      this.abilities.can('navigate insurance') && this.#insuranceMainMenuItem,
      this.#taxesAndDutiesMainMenuItem,
    ];
  }

  get #rawSettingsItems() {
    if (this.#shouldShowKDeposit) {
      return [this.#personalSettingsItem, this.#recommendQontoMainMenuItem];
    }
    return [
      this.#settingsMainMenuItem,
      this.#userManagementMainMenuItem,
      this.#integrationsAndPartnershipsMainMenuItem,
      this.#recommendQontoMainMenuItem,
    ];
  }

  get #shouldShowKDeposit() {
    let {
      currentAccount: hasAccount,
      organization: { underRegistration },
    } = this.organizationManager;

    return underRegistration || !hasAccount;
  }

  get #isSavingsSectionVisible() {
    let savingsAccounts = [];
    return (
      ((this.abilities.can('access savings-account') && savingsAccounts?.length) ||
        this.organizationManager.organization.nonClosedRemuneratedAccounts?.length ||
        this.abilities.can('navigate remunerated-account')) &&
      this.abilities.can('access bank-account')
    );
  }

  get #transactionsItem() {
    return {
      id: 'transactions',
      icon: 'icon_nav_pro_account',
      link: this.linkManager.createLink({ route: 'transactions' }),
      label: this.intl.t('dual-nav.sub.business-account.transactions'),
      tracking: 'menu_transactions_clicked',
    };
  }

  // Temporary item to display the transactions-v2 page for internal Qonto users
  get #transactionsV2Item() {
    return {
      id: 'transactions-v2',
      icon: 'icon_nav_pro_account',
      link: this.linkManager.createLink({ route: 'transactions-v2' }),
      label: 'Transactions V2',
    };
  }

  get #cardsItem() {
    return {
      id: 'cards',
      icon: 'icon_nav_cards',
      link: this.linkManager.createLink({ route: 'cards' }),
      label: this.intl.t('dual-nav.sub.business-account.cards'),
      tracking: 'menu_cards_clicked',
    };
  }

  get #reimbursementsEmployeeViewItem() {
    if (variation('feature--boolean-enable-reimbursements')) {
      if (
        this.abilities.cannot('use reimbursements request') ||
        this.abilities.cannot('review card request') ||
        this.abilities.cannot('review expense-report request') ||
        this.abilities.cannot('review mileage request') ||
        this.abilities.cannot('review transfer request') ||
        this.abilities.cannot('navigate reimbursements in request')
      )
        return;
    } else {
      if (this.abilities.cannot('use reimbursements request')) return;
    }

    return {
      id: 'reimbursements',
      icon: 'icon_nav_team_expenses',
      link: this.linkManager.createLink({ route: 'reimbursements.requests' }),
      label: variation('feature--boolean-ab-test-reimbursements')
        ? this.intl.t('menu.main.reimbursements-ab-test')
        : this.intl.t('menu.main.reimbursements'),
      tracking: 'menu_reimbursements_clicked',
    };
  }

  get #requestsItem() {
    return {
      id: 'requests',
      icon: 'icon_nav_requests',
      link: this.linkManager.createLink({ route: 'requests' }),
      label: this.intl.t('menu.main.requests'),
      tracking: 'menu_requests_clicked',
    };
  }

  get #transfersEmployeeViewItem() {
    return {
      id: 'transfers',
      icon: 'icon_nav_transfer',
      link: this.linkManager.createLink({ route: 'transfers.requests' }),
      label: this.intl.t('dual-nav.sub.business-account.transfers'),
      tracking: 'menu_transfers_clicked',
    };
  }

  get #capitalDepositMainMenuItem() {
    return {
      icon: 'icon-capital-deposit',
      id: 'k-deposit',
      label: this.intl.t('dual-nav.main.capital-deposit'),
      link: this.linkManager.createLink({ route: 'capital' }),
      tracking: 'menu_capital-deposit_clicked',
    };
  }

  get #overviewMainMenuItem() {
    return {
      icon: 'icon_nav_home_unselected',
      id: 'overview',
      label: this.intl.t('dual-nav.main.overview'),
      link: this.linkManager.createLink({ route: 'overview' }),
      position: 'top',
      tracking: 'menu_overview_clicked',
    };
  }

  get #tasksMainMenuItem() {
    let notificationCount = this.notifierCounterManager.counter?.request || null;

    return {
      icon: 'icon_nav_tasks',
      id: 'tasks',
      label: this.intl.t('dual-nav.main.tasks'),
      link: this.linkManager.createLink({ route: 'tasks' }),
      position: 'top',
      notificationCount,
      tracking: 'menu_tasks_clicked',
    };
  }

  get #businessAccountMainMenuItem() {
    let {
      organization: { slug },
      membership: { role },
    } = this.organizationManager;

    let accountsItem = {
      id: 'accounts',
      link: this.linkManager.createLink({ route: 'accounts' }),
      label: this.intl.t('dual-nav.sub.business-account.accounts'),
      tracking: 'menu_accounts_clicked',
    };

    let transfersItem = {
      id: 'transfers',
      link: this.linkManager.createLink({ route: 'transfers' }),
      label: this.intl.t('dual-nav.sub.business-account.transfers'),
      ...(this.abilities.can('navigate transfer') && {
        quickAction: {
          route: 'transfers.landing',
          model: slug,
          tooltip: this.intl.t('navigation.tooltips.create-transfer'),
          id: 'transfers',
          tracking: 'menu_transfers-quick-action_clicked',
        },
      }),
      tracking: 'menu_transfers_clicked',
    };

    let outgoingDirectDebitsItem = {
      id: 'outgoing-direct-debits',
      link: this.linkManager.createLink({ route: 'mandates' }),
      label: this.intl.t('dual-nav.sub.business-account.outgoing-direct-debits'),
      tracking: 'menu_outgoing-direct-debits_clicked',
    };

    let checksItem = {
      id: 'checks',
      link: this.linkManager.createLink({ route: 'checks' }),
      label: this.intl.t('dual-nav.sub.business-account.check-deposit'),
      tracking: 'menu_check-deposit_clicked',
    };

    let incomingDirectDebitsItem = {
      id: 'incoming-direct-debits',
      isNew: true,
      link: this.linkManager.createLink({ route: 'direct-debit-collections' }),
      label: this.intl.t('sdd-collections.title'),
      tracking: 'menu_incoming-direct-debits_clicked',
    };

    let overviewGroup = {
      label: this.intl.t('dual-nav.sub.business-account.overview.label'),
      items: [
        this.#transactionsItem,
        variation('feature--boolean-redesigned-transactions-table') && this.#transactionsV2Item,
        this.abilities.can('navigate bank-account') && accountsItem,
      ],
    };

    // Without the manager role check, the transfers item would also be shown to accountants
    // See PDCA: https://www.notion.so/qonto/About-3000-accountants-could-see-the-transfers-submenu-item-when-they-should-not-have-leading-to-a-69a11fcd3e094b11bcbee3d839d48abb?pvs=4
    let isManagerAndCantCreateRequests =
      role === ROLES.MANAGER &&
      this.abilities.cannot('create transfer') &&
      this.abilities.can('read request');

    // cleanup when modular pricing feature will be enabled
    if (!this.organizationManager.organization.hasModularPricing) {
      isManagerAndCantCreateRequests =
        role === ROLES.MANAGER &&
        this.abilities.cannot('review transfer request') &&
        this.abilities.can('read request');
    }

    let shouldShowTransfersItem =
      this.abilities.can('navigate transfer') || isManagerAndCantCreateRequests;

    let paymentMethodsGroup = {
      label: this.intl.t('dual-nav.sub.business-account.payment-methods.label'),
      items: [
        shouldShowTransfersItem && transfersItem,
        this.abilities.can('navigate card') && this.#cardsItem,
        this.abilities.can('navigate mandate') && outgoingDirectDebitsItem,
      ],
    };

    let collectionMethodsGroup = {
      label: this.intl.t('dual-nav.sub.business-account.collection-methods.label'),
      items: [
        this.abilities.can('navigate check') && checksItem,
        this.abilities.can('access directDebitCollection') && incomingDirectDebitsItem,
      ],
    };

    return {
      icon: 'icon_nav_pro_account',
      id: 'business-account',
      label: this.intl.t('dual-nav.main.business-account'),
      groups: [overviewGroup, paymentMethodsGroup, collectionMethodsGroup],
      tracking: 'menu_business-account_clicked',
    };
  }

  get #teamExpensesMainMenuItem() {
    let budgetsItem = {
      id: 'budgets',
      isLocked: this.abilities.cannot('use budget'),
      link: this.linkManager.createLink({ route: 'budgets' }),
      label: this.intl.t('dual-nav.sub.team-expenses.budgets'),
      tracking: 'menu_budgets_clicked',
    };

    let cardsQuickAccessItem = {
      id: 'cards',
      label: this.intl.t('dual-nav.sub.quick-access.cards'),
      link: this.linkManager.createLink({ route: 'cards.team' }),
      tracking: 'menu_team-cards_quick-access_clicked',
    };

    let reimbursementsPage = {
      id: 'reimbursements',
      isLocked: this.abilities.cannot('use reimbursements request'),
      link: this.linkManager.createLink({ route: 'reimbursements' }),
      label: variation('feature--boolean-ab-test-reimbursements')
        ? this.intl.t('menu.main.reimbursements-ab-test')
        : this.intl.t('menu.main.reimbursements'),
      tracking: 'menu_reimbursements_clicked',
    };

    let teamExpensesGroup = {
      items: [
        this.abilities.can('navigate reimbursements in request') && reimbursementsPage,
        this.abilities.can('navigate budget') && budgetsItem,
      ],
    };

    let shouldShowCardsQuickAccessItem =
      this.abilities.can('navigate card') && this.abilities.can('navigate team card');

    return {
      icon: 'icon_nav_team_expenses',
      id: 'team-expenses',
      label: this.subscriptionManager.hasFeature('team_management')
        ? this.intl.t('dual-nav.main.team-expenses')
        : this.intl.t('dual-nav.main.expenses'),
      groups: [teamExpensesGroup],
      quickAccessItems: shouldShowCardsQuickAccessItem ? [cardsQuickAccessItem] : [],
      tracking: 'menu_team-expenses_clicked',
    };
  }

  get #cashFlowMainMenuItem() {
    return {
      icon: 'icon_nav_overview',
      id: 'cash-flow',
      label: this.intl.t('dual-nav.main:cash-flow'),
      link: this.linkManager.createLink({ route: 'cash-flow' }),
      tracking: 'menu_cash_flow_clicked',
    };
  }

  get #savingsMainMenuItem() {
    return {
      icon: 'icon_nav_savings',
      id: 'savings',
      label: this.intl.t('dual-nav.main.savings'),
      link: this.linkManager.createLink({ route: 'savings' }),
      tracking: 'menu_savings_clicked',
    };
  }

  get #invoicesMainMenuItem() {
    let quotesItem = {
      id: 'quotes',
      link: this.linkManager.createLink({ route: 'quotes' }),
      label: this.intl.t('dual-nav.sub.invoices.quotes'),
      tracking: 'menu_quotes_clicked',
    };

    let clientInvoicesItem = {
      id: 'client-invoices',
      link: this.linkManager.createLink({ route: 'receivable-invoices' }),
      label: this.intl.t('dual-nav.sub.invoices.clients.client-invoices'),
      tracking: 'menu_client-invoices_clicked',
    };

    let invoiceSubscriptionsItem = {
      id: 'invoice-subscriptions',
      link: this.linkManager.createLink({ route: 'invoice-subscriptions' }),
      label: this.intl.t('dual-nav.sub.invoices.clients.recurring-invoices'),
      tracking: 'menu_recurring-invoices_clicked',
      isLocked: this.abilities.cannot('use invoice-subscription'),
    };

    let productsItem = {
      id: 'products',
      link: this.linkManager.createLink({ route: 'products' }),
      label: this.intl.t('dual-nav.sub.invoices.products.product-list'),
      isNew: this.abilities.can('read products'),
    };

    let clientListItem = {
      id: 'client-list',
      link: this.linkManager.createLink({ route: 'clients' }),
      label: this.intl.t('dual-nav.sub.invoices.clients.client-list'),
      tracking: 'menu_client-list_clicked',
    };

    let supplierInvoicesItem = {
      id: 'supplier-invoices',
      isLocked: this.abilities.cannot('use supplier-invoice'),
      link: this.linkManager.createLink({ route: 'supplier-invoices' }),
      label: this.intl.t('dual-nav.sub.invoices.supplier-invoices'),
      tracking: 'menu_supplier-invoices_clicked',
    };

    let legacySupplierListItem = {
      id: 'legacy-supplier-list',
      isLocked: this.abilities.cannot('use counterparty'),
      link: this.linkManager.createLink({ route: 'counterparties' }),
      label: this.intl.t('dual-nav.sub.invoices.supplier-list'),
      tracking: 'menu_supplier-list_clicked',
    };

    let suppliersListItem = {
      id: 'supplier-list',
      isLocked: this.abilities.cannot('view supplier'),
      link: this.linkManager.createLink({ route: 'suppliers' }),
      label: this.intl.t('dual-nav.sub.invoices.supplier-list'),
      tracking: 'menu_supplier-list_clicked',
    };

    // TODO modularPricing cleanup
    let showInvoiceSubscriptionItem = this.organizationManager.organization.hasModularPricing
      ? !this.organizationManager.organization.isSuspended
      : !this.organizationManager.organization.isSuspended &&
        this.abilities.can('create invoice-subscription');

    let showProductsItem =
      variation('feature-invoices-catalog-of-items') && this.abilities.can('read products');

    let showClientHubItem =
      variation('feature--boolean-client-hub') &&
      this.abilities.can('read client-hubs') &&
      this.abilities.can('write client-hubs');

    let showQuotesItem = this.abilities.can('create receivable-invoice');

    let clientsGroup = {
      label: this.intl.t('dual-nav.sub.invoices.clients.label'),
      items: this.abilities.can('navigate receivable-invoice')
        ? [
            showQuotesItem && quotesItem,
            clientInvoicesItem,
            showInvoiceSubscriptionItem && invoiceSubscriptionsItem,
            showProductsItem && productsItem,
            showClientHubItem && clientListItem,
          ]
        : [],
    };

    let suppliersGroup = {
      label: this.intl.t('dual-nav.sub.invoices.suppliers.label'),
      items: [
        this.abilities.can('navigate supplier-invoice') && supplierInvoicesItem,
        !variation('feature--boolean-supplier-hub') &&
          this.abilities.can('navigate counterparty') &&
          legacySupplierListItem,
        variation('feature--boolean-supplier-hub') &&
          this.abilities.can('view supplier') &&
          suppliersListItem,
      ],
    };

    return {
      icon: 'icon_nav_invoices',
      id: 'invoices',
      label: this.intl.t('dual-nav.main.invoices'),
      groups: [clientsGroup, suppliersGroup],
      tracking: 'menu_invoices_clicked',
    };
  }

  get #financingMainMenuItem() {
    let canAccessPayLater = this.abilities.can('access pay later in financing');

    let payLaterItem = {
      id: 'pay-later',
      link: this.linkManager.createLink({ route: 'financing.pay-later' }),
      label: this.intl.t('dual-nav.sub.financing.pay-later'),
      tracking: 'menu_pay-later_clicked',
    };

    let qontoGroup = {
      label: this.intl.t('dual-nav.sub.financing.qonto.label'),
      items: [payLaterItem],
    };

    let financingPartnersItem = {
      id: 'partner-offers',
      link: this.linkManager.createLink({ route: 'financing.partners' }),
      label: this.intl.t('dual-nav.sub.financing.all-partners'),
      tracking: 'menu_all-partners_clicked',
    };

    let partnersGroup = {
      label: this.intl.t('dual-nav.sub.financing.partners.label'),
      items: [this.abilities.can('navigate financing') && financingPartnersItem],
    };

    let financingButtonItem = {
      icon: 'icon_nav_financing',
      id: 'financing',
      label: this.intl.t('dual-nav.main.financing'),
      groups: [qontoGroup, partnersGroup],
      tracking: 'menu_financing_clicked',
    };

    let financingLinkItem = {
      icon: 'icon_nav_financing',
      id: 'financing',
      link: this.linkManager.createLink({ route: 'financing.partners' }),
      label: this.intl.t('dual-nav.main.financing'),
      tracking: 'menu_financing_clicked',
    };

    return canAccessPayLater
      ? financingButtonItem
      : this.abilities.can('navigate financing') && financingLinkItem;
  }

  get #insuranceMainMenuItem() {
    let managementGroup = {
      label: this.intl.t('dual-nav.sub.insurance.management.label'),
      items: [
        {
          id: 'insurance-policies',
          isNew: true,
          link: this.linkManager.createLink({ route: 'insurance-hub.policies' }),
          label: this.intl.t('dual-nav.sub.insurance.management.my-policies'),
          tracking: 'menu_insurance_my-policies_clicked',
        },
      ],
    };

    let categoryItems = [
      {
        id: 'insurance-businesses',
        link: this.linkManager.createLink({
          route: 'insurance-hub.categories',
          models: [INSURANCE_CATEGORIES.BUSINESSES],
        }),
        label: this.intl.t('dual-nav.sub.insurance.categories.for-businesses'),
        tracking: 'menu_insurance_for-businesses_clicked',
      },
      {
        id: 'insurance-owners',
        link: this.linkManager.createLink({
          route: 'insurance-hub.categories',
          models: [INSURANCE_CATEGORIES.OWNERS],
        }),
        label: this.intl.t('dual-nav.sub.insurance.categories.for-owners'),
        tracking: 'menu_insurance_for-owners_clicked',
      },
      {
        id: 'insurance-employees',
        link: this.linkManager.createLink({
          route: 'insurance-hub.categories',
          models: [INSURANCE_CATEGORIES.EMPLOYEES],
        }),
        label: this.intl.t('dual-nav.sub.insurance.categories.for-employees'),
        tracking: 'menu_insurance_for-employees_clicked',
      },
    ];

    let { isFrench } = this.organizationManager.organization;

    let categoriesGroup = {
      label: this.intl.t('dual-nav.sub.insurance.categories.label'),
      items: [
        {
          id: 'insurance-all',
          link: this.linkManager.createLink({
            route: 'insurance-hub.insurances',
          }),
          label: this.intl.t('dual-nav.sub.insurance.categories.all-insurance'),
          tracking: 'menu_insurance_all-insurance_clicked',
        },
        ...(isFrench ? categoryItems : []),
      ],
    };

    return {
      icon: 'icon_nav_insurance',
      id: 'insurance',
      link: this.linkManager.createLink({ route: 'insurance-hub.policies' }),
      label: this.intl.t('dual-nav.main.insurance'),
      groups: [managementGroup, categoriesGroup],
      tracking: 'menu_insurance_clicked',
    };
  }

  get #taxesAndDutiesMainMenuItem() {
    let { kybAccepted } = this.organizationManager.organization;

    let f24Item = this.abilities.can('navigate f24') && {
      id: 'f24',
      link: this.linkManager.createLink({ route: 'f24' }),
      label: this.intl.t('dual-nav.sub.taxes-and-duties.f24'),
      quickAction: {
        id: 'f24',
        model: { name: 'f24-manual-declaration', stepId: 'form-ordinario' },
        route: 'flows',
        tooltip: this.intl.t('dual-nav.quick-action.f24.tooltip'),
        tracking: 'menu_f24-quick-action_clicked',
      },
      tracking: 'menu_f24_clicked',
    };

    let pagoPaItem = {
      id: 'pagopa',
      link: this.linkManager.createLink({ route: 'pagopa' }),
      label: this.intl.t('dual-nav.sub.taxes-and-duties.pagopa'),
      quickAction: kybAccepted && {
        id: 'pagopa',
        model: { name: 'pagopa', stepId: 'notice-info' },
        route: 'flows',
        tooltip: this.intl.t('dual-nav.quick-action.pagopa.tooltip'),
        tracking: 'menu_pagopa-quick-action_clicked',
      },
      tracking: 'menu_pagopa_clicked',
    };

    let nrcItem = this.abilities.can('navigate nrc') && {
      id: 'nrc',
      link: this.linkManager.createLink({ route: 'agencia-tributaria' }),
      label: this.intl.t('dual-nav.sub.taxes-and-duties.aeat'),
      isNew: true,
    };

    return {
      icon: 'icon_nav_taxes_and_duties',
      id: 'taxes-and-duties',
      label: this.intl.t('dual-nav.main.taxes-and-duties'),
      groups: [
        {
          items: [f24Item, nrcItem, this.abilities.can('navigate pagopa') && pagoPaItem],
        },
      ],
      tracking: 'menu_taxes-and-duties_clicked',
    };
  }

  get #settingsMainMenuItem() {
    let shouldShowInsuranceItem =
      this.abilities.can('navigate card') &&
      !this.organizationManager.organization.underRegistration;

    let insuranceItem = shouldShowInsuranceItem && {
      id: 'insurance',
      label: this.intl.t('dual-nav.sub.settings.card-insurance'),
      link: this.linkManager.createLink({ route: 'settings.insurances' }),
      tracking: 'menu_card-insurance_clicked',
    };

    let myProfileGroup = {
      label: this.intl.t('dual-nav.sub.settings.my-profile.label'),
      items: [this.#personalSettingsItem, insuranceItem],
    };

    let organizationDetailsItem = this.abilities.can('view settings organization') && {
      id: 'organization-details',
      label: this.intl.t('dual-nav.sub.settings.organization-details'),
      link: this.linkManager.createLink({ route: this.#organizationDetailsRoute }),
      tracking: 'menu_organization-details_clicked',
    };

    let billingAndInvoicesItem = this.abilities.can('view settings organization') && {
      id: 'billing-and-invoices',
      label: this.intl.t('dual-nav.sub.settings.billing-and-invoices'),
      link: this.linkManager.createLink({ route: 'settings.price-plan' }),
      tracking: 'menu_price-plan-invoices_clicked',
    };

    let historicalDataItem = this.abilities.can('navigate post-migration') && {
      id: 'historical-data',
      label: this.intl.t('dual-nav.sub.settings.historical-data'),
      link: this.linkManager.createLink({ route: 'settings.historical-data' }),
      tracking: 'menu_historical-data_clicked',
    };

    let accountTransferItem = this.abilities.can('navigate bank-switch') && {
      id: 'account-transfer',
      label: this.intl.t('dual-nav.sub.settings.account-transfer'),
      link: {
        url: `${switchingBankJsURL}?organizationId=${this.currentOrganization?.id}`,
        target: '_blank',
      },
      tracking: 'menu_account-transfer_clicked',
    };

    let capitalIncreaseItem = this.abilities.can('navigate capital-deposit') && {
      id: 'capital-increase',
      label: this.intl.t('dual-nav.sub.settings.capital-increase'),
      link: {
        url:
          this.localeManager.locale === 'fr'
            ? 'https://qonto.typeform.com/capincreaseFR'
            : 'https://qonto.typeform.com/capincreaseENG',
        target: '_blank',
      },
      tracking: 'menu_capital-increase_clicked',
    };

    let organizationGroup = {
      label: this.intl.t('dual-nav.sub.settings.organization.label'),
      items: [
        organizationDetailsItem,
        billingAndInvoicesItem,
        historicalDataItem,
        accountTransferItem,
        capitalIncreaseItem,
      ],
    };

    let canSeeApprovalWorkflowItem =
      this.abilities.can('create approval-workflow') ||
      this.abilities.can('upsell approval-workflow');
    let approvalWorkflowItem = canSeeApprovalWorkflowItem && {
      id: 'approval-workflow',
      isLocked: this.abilities.can('upsell approval-workflow'),
      label: this.intl.t('dual-nav.sub.settings.approval-workflows'),
      link: this.linkManager.createLink({ route: 'settings.approval-workflows' }),
      tracking: 'menu_approval-section_clicked',
    };

    let receiptsForwardItem = this.abilities.can('navigate attachment') && {
      id: 'receipts-forward',
      label: this.intl.t('dual-nav.sub.settings.receipts-forward'),
      link: this.linkManager.createLink({ route: 'settings.receipts-forward' }),
      isLocked: this.abilities.cannot('use attachment'),
      tracking: 'menu_receipts-forward_clicked',
    };

    let missingReceiptsReminderItem = this.abilities.can('navigate receipt-reminder') && {
      id: 'receipts-reminder',
      label: this.intl.t('dual-nav.sub.settings.missing-receipt-reminders'),
      link: this.linkManager.createLink({ route: 'settings.receipts-reminder' }),
      tracking: 'menu_missing-receipt-reminders_clicked',
    };

    let analyticLabelsItem = this.abilities.can('navigate custom-labels') && {
      id: 'analytic-labels',
      label: this.intl.t('dual-nav.sub.settings.analytic-labels'),
      link: this.linkManager.createLink({ route: 'custom-labels' }),
      isLocked: this.abilities.cannot('use custom-labels'),
      tracking: 'menu_analytics-labels_clicked',
    };

    let toolsAndCustomizationsGroup = {
      label: this.intl.t('dual-nav.sub.settings.tools-customization.label'),
      items: [
        approvalWorkflowItem,
        receiptsForwardItem,
        missingReceiptsReminderItem,
        analyticLabelsItem,
      ],
    };

    return {
      id: 'settings',
      label: this.intl.t('dual-nav.main.settings'),
      groups: [myProfileGroup, organizationGroup, toolsAndCustomizationsGroup],
      tracking: 'menu_settings_clicked',
    };
  }

  get #personalSettingsItem() {
    return {
      id: 'personal-settings',
      link: this.linkManager.createLink({ route: 'settings.personal' }),
      label: this.intl.t('dual-nav.sub.settings.personal-settings'),
      tracking: 'menu_personal-settings_clicked',
    };
  }

  get #organizationDetailsRoute() {
    let { hasKycKybUpdateFeature } = this.organizationManager.organization;

    let route = hasKycKybUpdateFeature
      ? 'settings.company-profile'
      : 'settings.organization-profile';

    return route;
  }

  get #userManagementMainMenuItem() {
    let isUserAccessLocked = false;
    let isUserAccessNew = false;

    // TODO There are many acceptance tests without price plan setup so we use this workaround to make them pass
    if (this.subscriptionManager.currentPricePlan) {
      isUserAccessNew = this.abilities.can('add admin only members');
      isUserAccessLocked =
        this.abilities.cannot('add admin only members') && this.abilities.cannot('access members');
    }

    let userAccessItem = this.abilities.can('navigate members') && {
      id: 'user-access',
      isNew: isUserAccessNew,
      link: this.linkManager.createLink({ route: 'members' }),
      label: this.intl.t('dual-nav.sub.user-management.user-access'),
      isLocked: isUserAccessLocked,
      tracking: 'menu_user-access_clicked',
    };

    let guestAccessItem = this.abilities.can('navigate members') && {
      id: 'guest-access',
      link: this.linkManager.createLink({ route: 'guests' }),
      label: this.intl.t('dual-nav.user-management.accountant-access'),
      isLocked: this.abilities.cannot('use accountant-access'),
      tracking: 'menu_accountant-access_clicked',
    };

    let teamManagementItem = this.abilities.can('navigate teams') && {
      id: 'team-management',
      link: this.linkManager.createLink({ route: 'teams' }),
      label: this.intl.t('dual-nav.sub.user-management.team-management'),
      isLocked: this.abilities.cannot('use teams'),
      tracking: 'menu_team-management_clicked',
    };

    let userManagementGroup = {
      label: this.intl.t('dual-nav.sub.user-management.members.label'),
      items: [userAccessItem, teamManagementItem],
    };

    let guestManagementGroup = {
      label: this.intl.t('dual-nav.sub.user-management.guests.label'),
      items: [guestAccessItem],
    };

    return {
      id: 'user-management',
      label: this.intl.t('dual-nav.main.user-management'),
      groups: [userManagementGroup, guestManagementGroup],
      tracking: 'menu_user-management_clicked',
    };
  }

  get #integrationsAndPartnershipsMainMenuItem() {
    let featuredItem = this.abilities.can('navigate connect') && {
      id: 'featured',
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.featured'),
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.featured',
      }),
      tracking: 'menu_featured_clicked',
    };

    let integrationsItem = this.abilities.can('navigate connect') && {
      id: 'integrations',
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.integrations'),
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.collections',
        models: ['integrations'],
      }),
      tracking: 'menu_integrations_clicked',
    };

    let partnershipsItem = this.abilities.can('navigate connect') && {
      id: 'partnerships',
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.partnerships'),
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.collections',
        models: ['partnerships'],
      }),
      tracking: 'menu_partnerships_clicked',
    };

    let ebicsItem = this.abilities.can('access ebics connect') && {
      id: 'ebics',
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.ebics'),
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.collections',
        models: ['ebics'],
      }),
      tracking: 'menu_ebics_clicked',
    };

    let collectionsGroup = {
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.collections.label'),
      items: [featuredItem, integrationsItem, partnershipsItem, ebicsItem],
    };

    let allCategoriesItem = this.abilities.can('navigate connect') && {
      id: 'all-categories',
      label: this.intl.t('section-title.all-categories'),
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.applications',
      }),
      tracking: 'menu_all-categories_clicked',
    };

    let accountingItem = this.abilities.can('navigate connect') && {
      id: 'accounting',
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.categories',
        models: [CONNECT_CATEGORIES.ACCOUNTING],
      }),
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.accounting'),
      tracking: 'menu_category-accounting_clicked',
    };

    let banksItem = this.abilities.can('navigate connect') &&
      this.abilities.can('import external-account') && {
        id: 'banks',
        link: this.linkManager.createLink({
          route: 'settings.connect-hub.categories',
          models: [CONNECT_CATEGORIES.BANKS],
        }),
        label: this.intl.t('dual-nav.sub.integrations-and-partnerships.banks'),
        tracking: 'menu_category-banks_clicked',
      };

    let businessAndPaymentsItem = this.abilities.can('navigate connect') && {
      id: 'business-payments',
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.categories',
        models: [CONNECT_CATEGORIES.BUSINESS_PAYMENTS],
      }),
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.business-and-payments'),
      tracking: 'menu_category-business-payments_clicked',
    };

    let financeItem = this.abilities.can('navigate connect') && {
      id: 'finance',
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.categories',
        models: [CONNECT_CATEGORIES.FINANCE],
      }),
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.finance'),
      tracking: 'menu_category-finance_clicked',
    };

    let hrItem = this.abilities.can('navigate connect') && {
      id: 'hr',
      isNew: true,
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.categories',
        models: [CONNECT_CATEGORIES.HR],
      }),
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.hr'),
      tracking: 'menu_category-hr_clicked',
    };

    let cloudAndStorageItem = this.abilities.can('navigate connect') && {
      id: 'cloud-storage',
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.categories',
        models: [CONNECT_CATEGORIES.CLOUD_STORAGE],
      }),
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.cloud-and-storage'),
      tracking: 'menu_category-cloud-storage_clicked',
    };

    let productivityItem = this.abilities.can('navigate connect') && {
      id: 'productivity',
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.categories',
        models: [CONNECT_CATEGORIES.PRODUCTIVITY],
      }),
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.productivity'),
      tracking: 'menu_category-productivity_clicked',
    };

    let invoiceImportItem = this.abilities.can('navigate connect') && {
      id: 'invoice-import',
      isNew: true,
      link: this.linkManager.createLink({
        route: 'settings.connect-hub.categories',
        models: [CONNECT_CATEGORIES.INVOICE_IMPORT],
      }),
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.invoice-import'),
      tracking: 'menu_category-invoice-import_clicked',
    };

    let categoriesGroup = {
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.categories.label'),
      items: [
        allCategoriesItem,
        accountingItem,
        variation('feature--boolean-account-aggregation-marketplace') && banksItem,
        businessAndPaymentsItem,
        cloudAndStorageItem,
        ...[financeItem, hrItem],
        variation('feature--get-my-invoices') && invoiceImportItem,
        productivityItem,
      ],
    };

    let activeConnectionsItem = this.abilities.can('navigate oauth') && {
      id: 'active-connections',
      link: this.linkManager.createLink({ route: 'settings.connections' }),
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.active-connections'),
      tracking: 'menu_active-connections_clicked',
    };

    let apiKeyItem = this.abilities.can('navigate integration') && {
      id: 'api-key',
      link: this.linkManager.createLink({ route: 'settings.integrations' }),
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.api-key'),
      tracking: 'menu_api-key_clicked',
    };

    let managementGroup = {
      label: this.intl.t('dual-nav.sub.integrations-and-partnerships.management.label'),
      items: [activeConnectionsItem, apiKeyItem],
    };

    return {
      icon: 'icon-mono-integrations-and-partnerships',
      id: 'integrations-and-partnerships',
      label: this.intl.t('dual-nav.main.integrations-and-partnerships'),
      groups: [collectionsGroup, categoriesGroup, managementGroup],
      tracking: 'menu_integrations-and-partnerships_clicked',
    };
  }

  get #recommendQontoMainMenuItem() {
    return {
      id: 'recommend-qonto',
      label: this.intl.t('dual-nav.main.recommend-qonto'),
      link: this.linkManager.createLink({ route: 'settings.referrals' }),
      tracking: 'menu_recommend-qonto_clicked',
    };
  }
}
