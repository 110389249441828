export default {
  "dual-nav": "wq",
  "header": "wZ",
  "expanded": "wR",
  "collapsed": "wQ",
  "responsive": "we",
  "overlay": "wB",
  "visible": "wE",
  "footer": "wd"
};
