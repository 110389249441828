export default {
  "wrapper": "NJ",
  "active": "Ns",
  "expanded": "NF",
  "fade-in": "Ng",
  "hidden": "ND",
  "visible": "NV",
  "item-list": "NL",
  "item-list-slide-down": "NT",
  "dropdown-trigger": "Nz overlay",
  "settings-dropdown-slide-down": "Na",
  "responsive": "NH",
  "is-selected": "NO",
  "is-active": "Nm",
  "dropdown": "Np"
};
