/* import __COLOCATED_TEMPLATE__ from './application-cta.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { CONNECT_FEATURE_LEVELS } from 'qonto/constants/connect';
import { HRIS_CONNECT_FEATURE } from 'qonto/constants/hris-integration';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ApplicationCta extends Component {
  @service abilities;
  @service flowLinkManager;
  @service organizationManager;
  @service modals;
  @service segment;
  @service router;
  @service subscriptionManager;
  @service intl;
  @service sentry;
  @service toastFlashMessages;
  @service userManager;

  spinner = Spinner;

  get isExternalBank() {
    return Boolean(this.args.application.bank_id);
  }

  get isIntegration() {
    let { integration_type, tray_solution_id, kombo_connect_provider_name } = this.args.application;

    return (
      this.isExternalBank ||
      integration_type === 'internal_integration' ||
      tray_solution_id ||
      kombo_connect_provider_name
    );
  }

  get canActivateIntegration() {
    if (this.isKomboConnectButCantUse) {
      return false;
    }

    switch (this.args.application.minimum_integration_level) {
      case CONNECT_FEATURE_LEVELS.silver:
        return this.abilities.can('access silver connect');
      case CONNECT_FEATURE_LEVELS.gold:
        return this.abilities.can('access gold connect');
      case CONNECT_FEATURE_LEVELS.accounts_payable:
        return this.abilities.can('access accounts payable connect');
      case CONNECT_FEATURE_LEVELS.accounts_receivable:
        return this.abilities.can('access accounts receivable connect');
      default:
        return true;
    }
  }

  get isKomboConnectButCantUse() {
    return (
      this.args.application.kombo_connect_provider_name &&
      this.abilities.cannot('use hris-integration')
    );
  }

  get upsellTrigger() {
    if (this.isKomboConnectButCantUse) return HRIS_CONNECT_FEATURE;
    return this.args.application.minimum_integration_level;
  }

  // TODO modularPricing cleanup
  get hasModularPricing() {
    return this.organizationManager.organization.hasModularPricing;
  }

  @action uninstallApplication() {
    if (this.args.trackCta) {
      this.args.trackCta('disconnect');
    }
    this.args.uninstallApplication();
  }

  @action installApplication(applicationSlug) {
    if (this.args.trackCta) {
      this.args.trackCta('connect');
    }
    this.args.installApplication(applicationSlug);
  }

  onUpsellClickTask = dropTask(async ({ queryParams }) => {
    let featureCode = this.args.application.minimum_integration_level;

    await this.subscriptionManager.upgradeWithRecommendation(featureCode, {
      queryParams,
    });
  });

  @action
  joinWaitlist(application) {
    let { source } = this.args;
    let { name: integrationName, slug: integrationSlug } = application;
    let {
      currentUser: { firstName, email },
    } = this.userManager;
    let {
      organization: { id: organizationID },
    } = this.organizationManager;

    this.segment.track('gmi_integration_waitlist_joined', {
      organizationID,
      firstName,
      email,
      integrationName,
      integrationSlug,
      source,
    });

    return this.modals.open('connect/modals/join-waitlist');
  }

  @action
  onUpsellClick() {
    if (this.args.trackCta) {
      this.args.trackCta('upsell');
    }

    if (this.args.trialInfo) {
      this.modals.open('discover/trial/confirm', {
        isFullScreenModal: true,
        trialInfo: this.args.trialInfo,
      });
    } else {
      // TODO modularPricing cleanup
      if (!this.hasModularPricing) {
        return this.flowLinkManager.transitionTo({
          name: 'subscription-change',
          stepId: 'plans',
          queryParams: {
            upsellTrigger: this.upsellTrigger,
          },
        });
      }

      this.onUpsellClickTask
        .perform({
          queryParams: {
            upsellTrigger: this.upsellTrigger,
          },
        })
        .catch(ignoreCancelation)
        .catch(error => {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        });
    }
  }

  @action
  trackRedirect() {
    if (this.args.trackCta) {
      this.args.trackCta('redirect');
    }
  }
}
